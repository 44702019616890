.ms-cart,
.msc-cart {
    // Cart page is using the container header instead
    &__heading {
        display: none;
    }

    &__btn-checkout {
        @extend %button-primary;
        @include button-width-full;

        &:disabled,
        &.disabled {
            @extend %button-primary-disabled;
        }
    }

    &__btn-backtoshopping {
        @extend %button-secondary;
        @include button-width-full;
        margin-top: 1rem;

        &:disabled,
        &.disabled {
            @extend %button-secondary-disabled;
        }
    }

    &__btn-addcarttotemplate {
        @include button-remove;
        @extend %text-small;
        @extend %link;
        @extend %link-accent;
        @include link-text-decoration(underline);
        @include button-width-full;
        margin-top: 1rem;
        display: none !important; // Hide Order Template occurrences, possibly temporarily
    }

    .msc-cart-line {
        margin-bottom: 0.5rem;

        @include media-breakpoint-up(md) {
            margin-right: 2rem;
            margin-bottom: 0;
        }

        &__content,
        &__extra-actions {
            @include center-flex(y);
        }

        &,
        &__content,
        &__product {
            flex: 1;
        }

        &__product-image,
        &__invoice-image {
            margin-right: 1rem;

            img {
                width: 70px;
                max-height: 80px;
            }

            .msc-empty_image {
                @include image-placeholder(70px);
            }
        }

        &__product {
            margin-right: 1rem;
        }

        &__product-title {
            @extend %heading-minicart-product-title;
        }

        &__quantity label {
            display: none;
        }

        &__quantity__select-menu {
            @extend %select;
            @include flex-width(70px);
            margin-right: 1rem;
        }

        &__product-price {
            @extend %text-minicart-product-price;
            @include flex-width(80px);
            text-align: right;
        }

        &__extra-actions {
            justify-content: flex-end;
        }

        &__remove-item {
            @extend %button-minicart-product-remove;
            margin-right: 1rem;

            @include media-breakpoint-up(md) {
                margin-right: 2rem;
            }
        }

        &__add-to-order-template {
            @include button-remove;
            @extend %text-small;
            @extend %link;
            @extend %link-accent;
            @include link-text-decoration(underline);
            display: none !important; // Hide Order Template occurrences, possibly temporarily

            + div .msc-tooltip {
                display: none;
            }
        }
    }

    .msc-order-summary-wrapper,
    .msc-invoice-summary-wrapper {
        max-width: 650px;
        padding: 1rem;
        margin-top: 2rem;
        margin-left: auto;
        background-color: $color-background-accent;

        @include media-breakpoint-up(md) {
            padding: 2rem 3rem;
        }
    }

    &__empty-cart {
        margin-top: 2rem;
        text-align: center;

        .msc-cart-line {
            @extend %text-featured;
            justify-content: center;
            margin: 0;
        }

        .msc-cart__btn-backtoshopping {
            @include button-width-auto;
        }
    }
    
    &__customer-status-message {
        @extend %text-small;
        background-color: $color-background-neutral;
        color: $color-text-neutral;
        padding: 0.75rem 1rem;
        text-align: center;
    }
}

// NEW CUSTOMER RESTRICTIONS

.ms-content-block.new-customer {
    text-align: center;
    font-weight: $font-weight-bold;
    color: $color-rainforest;

    // SETTING LEARN MORE LINK
    & >
    div >
    div >
    p >
    * {
        color: $color-text;
        margin-left: 0.75rem;

        @include media-breakpoint-down(md) {
            grid-column: 1/3;
        }
    }

    // SETTING ICON
    & >
    div >
    div >
    p {
        @include center-flex(both);
        @include icon-image(
            $asset-icon-info,
            $width: 15px,
            $height: 17px,
            $color: $color-rainforest,
            $fit: unset
        );

        &::before {
            margin-right: 1rem;
        }

        @include media-breakpoint-down(md) { 
            display: grid;
            grid-template-columns: 1fr 11fr;
            align-items: unset;
        }
    }

}
