.msc-cart-line {
    @include center-flex(y);

    // @CUSTOM
    &__milling {
        @extend %text;
        @extend %text-small;

        &-label {
            margin-right: 0.25rem;
        }

        &-grade {
            font-weight: $font-weight-bold;
        }
    }

    &__product-variants {
        @extend %text;
        @extend %text-small;

        .name::before {
            content: ':';
            margin-right: 0.25rem;
        }
    }

    &__price-editor-container {
        @include center-flex(y);
        margin-left: 1rem;

        &__input {
            @extend %input;
            width: auto;
        }

        &__actions {
            margin-left: 0.75rem;

            > * {
                margin-right: 0.5rem;

                &:last-child {
                    margin-right: 0;
                }
            }

            &__price-editor-mode-selection {
                .msc-popover-inner {
                    @extend %container-account-dropdown;
                }

                &__expand-modes-button,
                button {
                    @include button-remove;
                    @extend %text-small;
                    @extend %link;
                    display: block;
                    text-align: left;
        
                    @include media-breakpoint-up(sm) {
                        margin-left: auto;
                        text-align: right;
                    }
                }
            }
        }
    }

    .msc-cartline__product-unit-of-measure {
        @extend %text;
        @extend %text-small;
    }

    &__product-inventory-label,
    &__add-to-wishlist {
        display: none;
    }

    // @CUSTOM
    &.cart-line-milling-service {
        .msc-cartline__product-unit-of-measure,
        .msc-cart-line__quantity__select-menu,
        .msc-cart-line__quantity {
            display: none;
        }

        + .msc-cart-line__extra-actions,
        + .msc-cart-line__remove-item {
            visibility: hidden;
            pointer-events: none;
        }
    }
}
