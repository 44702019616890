.ms-invoices-list,
.msc-invoices-list {
    width: 100%;

    @include media-breakpoint-up(sm) {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
    }

    &__header-heading {
        @extend %heading-h5; // Heading tag selection on the module doesn't seem to be working and defaults to h1
        margin-bottom: 1rem;
    }

    &__header-description {
        display: flex;
        align-items: flex-end;

        > * {
            margin-right: 0.5rem;

            &:last-child {
                margin-right: 0;
            }
        }

        &-title {
            @extend %text-large;
            font-weight: $font-weight-bold;

            &::after {
                content: ':';
            }
        }

        &-amount {
            @extend %text-large;
        }

        &-count {
            @extend %text-small;
            @extend %text-muted;
            margin-bottom: 3px;
        }
    }

    &__filter {
        @include media-breakpoint-down(xs) {
            width: 100%;
            margin-top: 0.5rem;
        }

        @include media-breakpoint-up(sm) {
            display: flex;
            justify-content: flex-end;
            flex: 1;
            margin-left: 1rem;
        }

        .msc-popover-inner {
            @extend %container-account-dropdown;
        }

        button {
            @include button-remove;
            display: block;
            text-align: left;

            @include media-breakpoint-up(sm) {
                margin-left: auto;
                text-align: right;
            }

            &.msc-invoices-list__filter__expand-filter-button {
                @include center-flex(y);

                &::after {
                    @extend %icon-account-dropdown;
                }

                &[aria-expanded='false']::after {
                    @extend %icon-account-dropdown-expand;
                }

                &[aria-expanded='true']::after {
                    @extend %icon-account-dropdown-collapse;
                }
            }
        }
    }

    &__empty-lines__container,
    &__container {
        width: 100%;
        margin-top: 2rem;

        &__actions {
            margin-top: 1.5rem;

            &__continue-shopping {
                @extend %button-secondary;

                &:disabled,
                &.disabled {
                    @extend %button-secondary-disabled;
                }
            }
        }
    }

    &__container {
        &__header {

            > * {
                margin-right: 0.75rem;
                margin-bottom: 1.5rem;

                &:last-child {
                    margin-right: 0;
                }
            }

            &__make-payment {
                @extend %button-primary;

                &:disabled,
                &.disabled {
                    @extend %button-primary-disabled;
                }

                @include media-breakpoint-down(md) {
                    margin-bottom: 1rem;
                }
            }

            button:not(.msc-invoices-list__container__header__make-payment) {
                @extend %button-secondary;

                &:disabled,
                &.disabled {
                    @extend %button-secondary-disabled;
                }
            }

            display: none;

        }

        &__content {
            overflow-x: auto;

            &__table {
                @extend %text-small;
                width: 100%;
                min-width: 800px;

                @include media-breakpoint-down(md) {
                    min-width: unset;
                }

                &__header {
                    border-bottom: 1px solid $color-border;
                    text-transform: uppercase;
                }

                &__header__row,
                &__row {
                    > * {
                        padding: 0.25rem;

                        &:first-child {
                            padding-left: 0;
                        }

                        &:last-child {
                            padding-right: 0;
                            display: none;
                        }

                        // None of the table columns have classes
                        &:first-child { // Select checkbox
                            width: 24px;
                        }
                    }

                    .checkbox-container {
                        margin-top: 2px;
                        margin-bottom: 0;
                    }
                }

                &__row {
                    &:first-child > * {
                        padding-top: 0.5rem;
                    }

                    &:last-child > * {
                        padding-bottom: 0.5rem;
                    }

                    &__open-invoice {
                        @extend %link-accent;
                        @include link-text-decoration(underline);
                    }

                    &__pay-invoice,
                    &__requestInvoiceButton {
                        @include button-remove;
                        @extend %link;
                        @include link-text-decoration(underline);
                        text-align: left;

                        &:disabled,
                        &.disabled {
                            @extend %link-muted;
                            @include link-text-decoration(none);
                        }

                        display: none;

                    }

                    &__pay-invoice.msc-btn {
                        margin-right: 0.5rem;
                    }

                    // Mobile Invoices tables
                    &__extra-actions-cell {
                        display: flex;
                        align-items: flex-end;
                        flex-direction: column;

                        &__toggle {
                            @include button-remove;
                            @include center-flex(y);

                            &::after {
                                @extend %icon-account-dropdown;
                                @extend %icon-account-dropdown-expand;
                            }
                        }

                        &__actions-container {
                            display: flex;
                            align-items: flex-end;
                            margin-top: 1rem;
                            position: absolute;
                            background-color: $color-white;
                            border: 1px solid $color-lunar;
                            padding: 0.5rem 1rem;

                            > * {
                                @include button-remove;
                                @extend %link;
                                @include link-text-decoration(underline);
                                text-transform: lowercase;
                                margin-right: 0.5rem;

                                &:last-child {
                                    margin-right: 0;
                                }
                            }

                            > &__pay-invoice,
                              &__requestInvoiceButton  {
                                display: none;
                            }
                        }
                    }

                    b {
                        font-weight: $font-weight-normal;
                    }
                }
            }
        }
    }

    &_requestInvoice__modal { // MS forgot to add another underscore before requestInvoice here
        @include media-breakpoint-up(md) {
            min-width: 400px !important;
        }

        .msc-modal__title {
            @extend %heading-h4;
        }
    }

    &__requestInvoice {
        &__modalBody label {
            margin-bottom: 0;
        }

        &__sendToEmail {
            @extend %text-small;
            @extend %text-muted;
            margin-bottom: 1rem;
        }

        &__searchBar {
            @extend %input;
            width: auto;

            @include media-breakpoint-up(md) {
                min-width: 250px;
            }
        }

        &__searchButton {
            @include button-remove;
            @extend %icon-search-search;
            margin-left: 1rem;
        }

        &__searchResultDisplay {
            @extend %text-small;
            margin-bottom: 1rem;
        }

        &__search {
            @include center-flex(y);

            + div:not([class]) { // Search results div is classless
                margin-top: 1rem;
                margin-bottom: 1rem;
            }
        }

        &__filteredInvoiceResult {
            @include center-flex(y);

            input {
                margin-right: 0.5rem;
            }

            label {
                @extend %text-small;
                margin-bottom: 0;
            }
        }

        &__submitRequest {
            @extend %button-primary;

            &:disabled,
            &.disabled {
                @extend %button-primary-disabled;
            }
        }

        &__cancelRequest {
            @extend %button-secondary;
            margin-left: 0.75rem;

            &:disabled,
            &.disabled {
                @extend %button-secondary-disabled;
            }
        }
    }

    .msc-alert-error,
    .msc-alert-danger {
        @extend %alert;
        @extend %alert-danger;
        margin-bottom: 1rem;
    }
}
