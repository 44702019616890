.umg-checkout-delivery-options {
    &__request-ship-date {
        margin-top: 1.5rem;

        &-label {
            @extend %label;
            display: block;
            margin-bottom: 0.5rem;
        }

        &-input {
            @extend %input;
            width: auto;
        }
    }
}

.tms-delivery-options {
    @extend %text;
    @extend %text-small;
    max-width: 400px;

    > * {
        margin-bottom: 0.75rem;
    }

    &__option-label {
        @include center-flex(y);
        justify-content: space-between;
        margin-bottom: 0.25rem;
    }

    &__option-input {
        margin-right: 0.75rem;
    }

    &__option-name {
        flex: 1;
        font-weight: $font-weight-bold;
    }

    &__option-price,
    &__fee-price {
        margin-left: 1rem;

        @include media-breakpoint-up(lg) {
            @include flex-width(75px);
        }
    }

    &__address,
    &__fees,
    &__add-ons {
        margin-left: 1.5rem;
    }

    &__address-text {
        max-width: 200px;
    }

    &__fee-label {
        @include center-flex(y);
        justify-content: space-between;
        margin-bottom: 0;
    }

    &__add-ons {
        margin-top: 0.75rem;

        &-title {
            @extend %heading-h6;
            @extend %text-small;
            @extend %text-muted;
            margin-bottom: 0.25rem;
        }

        .tms-delivery-options__option-label {
            margin-bottom: 0;
        }

        .tms-delivery-options__add-on-input {
            margin-right: 0.5rem;
        }

        .tms-delivery-options__option-name {
            font-weight: $font-weight-normal;
        }
    }

    &__error {
        @extend %alert;
        @extend %alert-danger;
        margin-bottom: 1rem;
    }
}
